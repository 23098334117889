@import 'style/colors.scss';

$hui-select-color-light: $hui-color-light-grey !default;
$hui-select-color-dark: $hui-color-dark !default;
$hui-select-color-trivial: $hui-color-trivial !default;
$hui-select-color-important: $hui-color-important !default;
$hui-select-color-border: $hui-color-border !default;
$hui-select-color-border-dark: $hui-color-border-dark !default;
$hui-select-color-border-active: $hui-select-color-border-dark !default;
$hui-select-color-border-active-dark: $hui-select-color-border !default;
$hui-select-border-radius: 5px !default;
$hui-select-font-default: $hui-font-default !default;

.hui-select {
  position: relative;
  margin-top: 0.5em;

  &__select {
    cursor: pointer;
    width: 100%;
    font-family: $hui-select-font-default;
    font-size: 1.125rem;
    outline: none;
    border: solid 1px $hui-select-color-border;
    background-color: $hui-select-color-light;
    border-radius: $hui-select-border-radius;
    transition: border-color 200ms, background-color 200ms;
    padding: 0.6115em 0.6em;
    appearance: none;
    &:focus {
      border-color: $hui-select-color-border-active-dark;
      & + .hui-select__label {
        color: $hui-select-color-dark;
      }
    }
  }

  &__label {
    color: $hui-color-trivial;
    background-color: $hui-select-color-light;
    pointer-events: none;
    position: absolute;
    padding: 0 0.25em;
    transition: all 200ms;
    left: 0.75em;
    top: -0.5em;
    letter-spacing: 0.01875em;
    font-size: 0.75rem;
    border-radius: 3px;
  }

  &::after {
    content: "›";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 1em;
    font-size: 1.75em;
    font-weight: 300;
    transform: rotate(90deg);
    pointer-events: none;
  }

  &--block {
    width: 100%;
  }

  &--has-value,
  &--dark {
    .hui-select__select {
      background-color: $hui-select-color-dark;
      border-color: $hui-select-color-border-dark;
      color: $hui-select-color-light;
      &:focus {
        border-color: $hui-select-color-border-active;
        & + .hui-select__label {
          background-color: $hui-select-color-dark;
          color: $hui-select-color-light;
        }
      }
    }
    .hui-select__label {
      background-color: $hui-select-color-dark;
    }
    &::after {
      color: $hui-select-color-border;
    }
  }
}
