@import 'style/colors.scss';

.card {
  display: flex;
  box-shadow: none;
  background: white;
	margin: 0 2vw 4vw 0;
  overflow: hidden;
  position: relative;
  height: 100%;
  border-radius: 12px;
  box-shadow: 3px 3px 8px hsl(0, 0%, 80%);

  a {
    color: black;
    text-decoration: none;
  }

  .card-content {
    padding: 0;
  }

  .card-content h2 {
    margin-top: 0;
    margin-bottom: .5em;
    font-weight: bold;
  }

 >button {
    display: inline-block;
    width: 100%;
    border: inherit;
    padding: 1rem 2rem;
    margin: 0;
    text-decoration: none;
    background: inherit;
    color: inherit;
    font-family: inherit;
    font-size: 1rem;
    line-height: 1;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      outline: 1px dashed $hqvorange;
      outline-offset: -10px;
    }

    &:active {
      transform: scale(0.99);
    }

    
    &:hover, &:focus-within {
      color: white;
      background-color: $hqvorange;
      border-style: solid;
      border-top: 2px solid $hqvorange;
      border-left: 2px solid $hqvorange;
      border-right: 2px solid $hqvorange;
      border-bottom: 2px solid $hqvorange;
    }


    > h4 {
      margin: 0.5em 0;
    }
    > p {
      margin: 0.3em 0;
    }
    
    img {
      display: block;
      border: 0;
      width: 100%;
      height: auto;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
  }
}

.grid {
  >div.column {
    &.column- {
      &2{
        .card {
          flex: 0 1 calc(50% - 2vw);
        }
      }
      &3{
        .card {
          flex: 0 1 calc(33% - 2vw);
        }
      }
    }
  }
}
