@import 'style/colors.scss';

.recipe-item {
  display: flex;
  flex-direction: column;

  button {
    color: $hqvorange;
    font-weight: bold;
  }

  div.img-card {
    margin: 0 1.0em 0.5em 0;
    span {
      display:table;
      margin:0 auto;
      font-weight: 100;
      color: $hqvgrey4;
      margin: 0.4em auto;
      font-size: small;
    }
    img {
      width: auto;
      max-height: 4em;
      box-shadow: none;
      background: $hui-color-light-grey;
      padding: 0.5em;
      border-radius: 16px;
      box-shadow: 3px 3px 8px hsl(0, 0%, 80%);
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;

    >span {
      flex-grow: 0;
      flex-shrink: 1;
      white-space: nowrap;
    }

    .padded {
      padding: 0 1em;
    }

    .three {
      flex-grow: 3;
      flex-shrink: 3;
      flex-basis: 0%;
    }
  }

  .row.space {
    justify-content: space-between;
  }

  .product-number {
    color: $hqvgrey5;
    font-weight: 100;
  }


  .heading {
    background-color: $hqvgrey3;
    padding: 0.1em 0;
  }

  .bold {
    font-weight: bolder;
  }

  .tool {
    margin: 1em 1em 0.5em;
  }
}
