@import 'style/colors.scss';

@font-face {
  font-family: 'Trade Gothic';
  src: url('./style/fonts/HusqvarnaGothic-Regular.woff') format('woff');
  font-weight: normal;
}

@font-face {
  font-family: 'Trade Gothic';
  src: url('./style/fonts/HusqvarnaGothic-Bold.woff') format('woff');
  font-weight: bold;
}

body {
  font-family: 'Trade Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: $hqvblack;
  background-color: $hui-color-light-grey;
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 800px;

  svg {
    fill: currentColor;
  }

  div {
    scrollbar-width: thin;
    scrollbar-color: $hqvgrey4 $hqvgrey1;

    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      background: $hqvgrey1;
    }
    ::-webkit-scrollbar-thumb {
      background: $hqvgrey4;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

}

@media screen and (max-width: 800px) {
  body {
    h1 {
      font-size: x-large;
    }
    h4 {
      font-size: small;
    }
    li {
      font-size: small !important;
    }
    p {
      font-size: x-small;
    }
  }  
}

@media screen and (max-width: 400px) {
  body {
    h1 {
      font-size: large;
    }
    h4 {
      font-size: small;
    }
    li {
      font-size: x-small !important;
    }
    p {
      font-size: x-small;
    }
  }  
}
