$hqvgrey1: #EAEAEA;
$hqvgrey2: #e2e3e6;
$hqvgrey3: #d2d2d2;
$hqvgrey4: #999999;
$hqvgrey5: #595959;
$hqvsteelgrey: #3d3c3c;
$hqvcpgrey: #2c2c2c;
$hqvblack: #201e1e;
$hqvorange: #f35321;
$hqvblue: #273a60;

// DBP UI colors
$hbd-color-1: #686867;
$hbd-color-2: #FFFFFF;
$hbd-color-3: #3D3D3C;
$hbd-color-4: #D2D2D1;
$hbd-color-5: #111111;
$hbd-color-6: #EEEEED;
$hbd-color-7: #273A60;
$hbd-color-8: #F25420;
$hbd-color-9: #000000;
$hbd-color-10: #3D3D3C;
$hbd-color-11: #00AF41;
$hbd-color-12: #EEEEED;
$hbd-color-13: #B0B0B1;
$hbd-color-14: #E41E2D;

// HBD additional color names
$hbd-color-primary: $hbd-color-11;
$hbd-color-secondary: $hbd-color-5;
$hbd-color-tertiary: $hbd-color-1;
$hbd-color-danger: $hbd-color-14;

// HUI Color Overrides.
$hui-color-primary: $hbd-color-8;
$hui-color-secondary: $hbd-color-5;
$hui-color-tertiary: $hbd-color-1;

$hui-article-panel-item-border-active: $hbd-color-11;
$hui-article-panel-item-border-active-size: 2px;
$hui-toggler-button-color-dark-light: $hbd-color-11;

$hui-gutter-sm: 40px;
$hui-gutter-md: 80px;

$hui-color-background: #E8E9EB !default;
$hui-color-light: #fff !default;
$hui-color-dark: #111 !default;
$hui-color-important: #F25420 !default;
$hui-color-trivial: #999999 !default;
$hui-color-border: #DDD !default;
$hui-color-border-dark: #333 !default;
$hui-color-brand: #273A60 !default;
$hui-color-dark-light: #222 !default;
$hui-color-light-grey: #f4f4f4 !default;
$hui-color-danger: #e41f2d !default;
$hui-color-contrast: $hui-color-light !default;

$hui-font-default: 'Trade Gothic', 'Helvetica Neue', Helvetica, Arial, sans-serif;
