.hui-btn-group {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  &__btn {
    margin: 2px;
  }
}