@import 'style/colors.scss';

.floor-grinding {
  .flex-column {
    display: flex;
    flex-direction: row;
    align-items: center;
    .one {
      flex-shrink: 1;
      flex-grow: 0;
      flex-basis: auto;
      &.grow {
        flex-grow: 1;

        h1 {
          text-transform: uppercase;
        }
      }
    }
  }


  hr {
    border-top: 1px solid $hqvgrey4;
    margin-block-start: 0;
    margin-block-end: 1vh;
  }

  .flex-row {
    display: flex;
    flex-direction: column;
  }

  .content {
    max-height: 84vh;
    overflow-y: scroll;

    >div >div >h1{
      margin-block-start: 0;
    }
  }

  .floating {
    position: fixed;
    width: 20vw;
    left: 40vw;
    top: 90vh;
  }
}