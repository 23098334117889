@import 'style/colors.scss';

$hui-text-color-light: $hui-color-light-grey !default;
$hui-text-color-dark: $hui-color-dark-light !default;
$hui-text-color-trivial: $hui-color-trivial !default;
$hui-text-color-important: $hui-color-important !default;
$hui-text-color-border: $hui-color-border !default;
$hui-text-color-border-dark: $hui-color-border-dark !default;
$hui-text-border-radius: 5px !default;

.hui-text {
  position: relative;
  margin: 2px;
  margin-top: 0.5em;
  background-color: $hui-color-light-grey;

  &__input {
    width: 100%;
    font-size: 1.125rem;
    border: solid 1px $hui-text-color-border;
    outline: none;
    background-color: $hui-text-color-light;
    border-radius: $hui-text-border-radius;
    transition: border-color 200ms, background-color 200ms;
    padding: 0.6115em 0;
    padding-left: 0.5em;

    &:focus {
      border-color: $hui-text-color-border-dark;
      & + .hui-text__label {
        color: $hui-text-color-dark;
      }
    }

    &:focus,
    &:not(:placeholder-shown) {
      & + .hui-text__label {
        transform: translateY(-1.4em);
        background-color: $hui-text-color-light;
        font-size: 0.75rem;
      }
    }
  }

  &__label {
    color: $hui-text-color-trivial;
    letter-spacing: 0.01875em;
    pointer-events: none;
    position: absolute;
    padding: 0 0.25em;
    transition: all 200ms;
    left: 0.75em;
    top: 0.8em;
    border-radius: 3px;
  }

  &--invalid {
    & .hui-text__input {
      background-color: rgba($hui-text-color-important, 0.1);
      border-color: $hui-text-color-important;
      & + .hui-text__label {
        color: $hui-text-color-important;
      }
    }
    & .hui-validation--error {
      color: $hui-color-danger;
    }
  }

  &--dark {
    .hui-text__input {
      background-color: $hui-text-color-dark;
      border-color: $hui-text-color-border-dark;
      color: $hui-text-color-light;
      &:focus {
        border-color: $hui-text-color-border;
        & + .hui-text__label {
          background-color: $hui-text-color-dark;
          color: $hui-text-color-light;
        }
      }
      &:focus,
      &:not(:placeholder-shown) {
        & + .hui-text__label {
          background-color: $hui-text-color-dark;
        }
      }
    }
  }
  &.hui-text--invalid {
    border-color: $hui-text-color-important;
  }
}

.hui-text-suffix {
  border: none;
  background: transparent;
  position: absolute;
  right: 0.5em;
  padding: 0;
  margin: 0;
  top: 1em;
  &__icon {
    height: 1.5em;
    width: 1.5em;
  }
}
