$hui-btn-small-height: 30px !default;
$hui-btn-medium-height: 40px !default;
$hui-btn-large-height: 50px !default;
$hui-btn-small-padding: 25px !default;
$hui-btn-medium-padding: 25px !default;
$hui-btn-large-padding: 25px !default;

$hui-btn-border-radius-small: 20em !default;
$hui-btn-border-radius-regular: 20em !default;
$hui-btn-border-radius-large: 20em !default;

.hui-btn {
  $border-width: 2px;
  height: $hui-btn-medium-height;
  padding: 0 $hui-btn-medium-padding;
  border-width: $border-width;
  border-radius: $hui-btn-border-radius-regular;
  
  &--small {
    $border-width: 1px;
    height: $hui-btn-small-height;
    padding: 0 $hui-btn-small-padding;
    border-width: $border-width;
    border-radius: $hui-btn-border-radius-small;
  }

  &--large {
    $border-width: 2px;
    height: $hui-btn-large-height;
    padding: 0 $hui-btn-large-padding;
    border-width: $border-width;
    border-radius: $hui-btn-border-radius-large;
  }

  &--block {
    width: 100%;
  }
}