@import 'style/colors.scss';

.hui-btn {
  display: inline-flex;
  font-weight: 700;
  font-size: .75rem;
  letter-spacing: .0625em;
  cursor: pointer;
  background-color: $hui-color-dark;
  color: $hui-color-light;
  border-style: solid;
  border-color: $hui-color-dark;
  user-select: none;
  white-space: nowrap;
  align-items: center;
  text-align: center;
  justify-content: center;
  text-decoration: none;


  &__icon {
    width: 1.67em;
    height: 1.67em;
    margin-left: 0.67em;

    &--left {
      margin-left: 0;
      margin-right: 0.67em;
    }
  }

  .hui-text-button {
    font-family: $hui-font-default;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .7px;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
  }
}
