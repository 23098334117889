@import 'style/colors.scss';

.hui-btn {
  &--primary {
    background-color: $hui-color-primary;
    border-color: $hui-color-primary;
    &.hui-btn--outlined {
      background-color: transparent;
      border-color: $hui-color-primary;
      color: $hui-color-primary;
    }

    &:hover {
      border-color: #D34212;
      background-color: #D34212
    }
  }

  &--secondary {
    background-color: $hui-color-secondary;
    border-color: $hui-color-secondary;
    &.hui-btn--outlined {
      background-color: transparent;
      border-color: $hui-color-secondary;
      color: $hui-color-secondary;
    }
  }

  &--danger {
    background-color: $hui-color-danger;
    border-color: $hui-color-danger;
    &.hui-btn--outlined {
      background-color: transparent;
      border-color: $hui-color-danger;
      color: $hui-color-danger;
    }
  }

  &--contrast {
    background-color: $hui-color-contrast;
    border-color: $hui-color-contrast;
    color: $hui-color-dark;
    &.hui-btn--outlined {
      background-color: transparent;
      border-color: $hui-color-contrast;
      color: $hui-color-contrast;
    }
  }

  &--disabled {
    background-color: $hui-color-border;
    border-color: $hui-color-border;
    color: #6d6d6d;
    &:hover {
      border-color: $hui-color-border;
      background-color: $hui-color-border;
      cursor: not-allowed;
    }
  }

  &--tertiary {
    background-color: transparent;
    border-color: $hui-color-trivial;
    color: $hui-color-secondary;
    &.hui-btn--active {
      border-color: $hui-color-primary;
      background-color: $hui-color-background;
    }
  }

  &--ghost {
    background-color: transparent;
    border-color: transparent;
    color: inherit;
  }

}

.hui-unstyled-btn {
  display: inline-flex;
  align-items: center;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  height: auto;
}
