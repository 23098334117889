@import 'style/colors.scss';
/* stylelint-disable */
@keyframes SpinnerAnimation {
  0% {
    fill-opacity: 1;
  }

  49% {
    fill-opacity: 1;
  }

  50% {
    fill-opacity: 0;
  }

  99% {
    fill-opacity: 0;
  }

  100% {
    fill-opacity: 1;
  }
}


$animationTime: 2s;

.Spinner {
  position: relative;
  .spinner {
    position: relative;

    .fill-wrapper {
      .circle1,
      .circle2,
      .circle3,
      .circle4,
      .circle5,
      .circle6 {
        animation: SpinnerAnimation $animationTime infinite;
        fill-opacity: 0;
      }

      .circle2 {
        animation-delay: calc(calc($animationTime/12) * 1);
      }

      .circle3 {
        animation-delay: calc(calc($animationTime/12) * 2);
      }

      .circle4 {
        animation-delay: calc(calc($animationTime/12) * 3);
      }

      .circle5 {
        animation-delay: calc(calc($animationTime/12) * 4);
      }

      .circle6 {
        animation-delay: calc(calc($animationTime/12) * 5);
      }
    }

    &.base-orange500 {
      .base-wrapper {
        color: $hqvorange;
      }
    }
    &.fill-orange500 {
      .fill-wrapper {
        color: $hqvorange;
      }
    }

    &.base-grey300 {
      .base-wrapper {
        color: $hqvgrey3;
      }
    }
    &.fill-grey300 {
      .fill-wrapper {
        color: $hqvgrey3;
      }
    }
  }
}

/* stylelint-enable */
