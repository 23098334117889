@import 'style/colors.scss';

a.holder-reference {
  color: white;
  text-decoration: inherit;
  background: $hqvgrey5;
  
  &:hover {
    background: $hui-color-important;
    color: inherit;
  }
}
.recipe {
  flex: 1;
  
  &-info {
    padding: 0.2em 0.5em;
    font-weight: bold;
    text-align: center;
  }

  &-modal {
    display: flex;
    flex-direction: column;
    box-shadow: none;
    background: #f4f4f4;
    overflow: hidden;
    padding: 1em 2em;
    border-radius: 16px;
    min-height: 50vh;

    div.header {
      display: flex;
      flex-direction: row;

      button {
        margin-left: auto;
        padding: 0;
      }
    }

    button.hui-btn--primary {
      margin-top: auto;
    }
  }
}
