@import 'style/colors.scss';

.toolsystem-modal {
  display: flex;
  flex-direction: column;
  box-shadow: none;
  background: #f4f4f4;
  overflow: hidden;
  padding: 1em 2em;
  border-radius: 16px;
  min-height: 50vh;

  div.header {
    display: flex;
    flex-direction: row;

    button {
      margin-left: auto;
      padding: 0;
    }
  }

  label {
    padding: 0 0 2em 0;

    >input[type='radio'] {
      margin: 3px 12px 0px 3px;

      &:after {
        width: 15px;
        height: 15px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: $hqvgrey2;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 2px solid black;
      }
      &:checked:after {
        background-color: $hui-color-primary;
      }
    }
  }
  button.hui-btn--primary {
    margin-top: auto;
  }
}
