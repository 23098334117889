@import 'style/colors.scss';

.overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0,0,0,0.5); /*dim the background*/
}
.modal {
  background: rgba(0,0,0,0);;
  position: absolute;
  float: left;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
