@import 'style/colors.scss';

ul.step-indicator {
  margin-top: 0;
  margin-bottom: 1vh;
  padding-inline-start: 0;

  &>li {
    list-style-type: none;
    width: 20%;
    float: left;
    font-size: medium;
    font-weight: bolder;
    letter-spacing: 0.1em;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: $hqvgrey4;
    
    &:before {
      width: 1vh;
      height: 1vh;
      content: " ";
      line-height: 1vh;
      border: 1vh solid $hqvgrey1;
      display: block;
      text-align: center;
      margin: 0 auto 10px auto;
      border-radius: 50%;
      background-color: $hqvgrey1;
    }
    &:after {
      width: 100%;
      height: 2px;
      content: '';
      position: absolute;
      background-color: $hqvgrey1;
      top: 1.5vh;
      left: 0;
      z-index: -1;
    }
  }
  
  &>li:first-child:after {
    left: 50%;
    width: 50%;
  }
  &>li:last-child:after {
    width: 50%;
  }
  &>li.active {
    color: $hqvorange;

    &::before {
      background-color: $hui-color-light-grey;
      border-color: $hqvorange;
    }
    &::after {
      background-color: $hqvorange;
    }
  }


  &>li.active.current {
    color: $hqvorange
  }
  &>li.active.current:before {
    border-color: $hqvorange;
  }
  &>li.active.current + li:after {
    background-color: $hqvgrey1;
  }
}
